<template>
<div id="">
    <b-navbar id="nav"  :transparent="true">
        <template slot="brand">
            
        </template>
        <template slot="start"> </template>

        <template slot="end">
            <b-navbar-item id="nav-item" :transparent="true" tag="router-link" to="/purchase">
            <CartButton v-if="cartItemsLength && !hideNavbar"
                        :buttonText="cartItemsLength"
                        :buttonId="'cart'"
                        :buttonWidth="'70px'"
                        @cartClick="onPurchaseClick()"/>
            </b-navbar-item>
        </template>
    </b-navbar>
    </div>
</template>

<script >
import { mapState } from 'vuex';
import { Component, Vue, Watch } from "vue-property-decorator";
//import { mutators } from "@/store/mutators";
import CartButton from "@/components/buttons/CartButton.vue"

@Component({
    components: {
            CartButton
    },
})
export default class NavBar extends Vue {
    cartItemsLength = this.$store.getters.cart.length;
    showCartButton = !this.$store.getters.showReactSendEmail
    hideNavbar = this.$store.getters.showReactSendEmail


    onPurchaseClick() {
        //console.log("Buy now was clicked")
        this.$router.push("/purchase");
    }
    onAboutClick() {
        //console.log("About was clicked ")
        this.showDemoTip = false;
        //this.$forceUpdate();
        //console.log("this.$route.name: " + this.$route.name)
        router.push({path: "/about"});
    }

    @Watch('$store.getters.cart')
    onUploadChange(value, oldValue) {
        console.log("cartItemsLength: " + value)
        this.cartItemsLength = this.$store.getters.cart.length;
    }

    @Watch('$store.getters.showReactSendEmail')
    onShowReactEmailChange(value, oldValue) {
        console.log("onShowReactEmailChange: " + value)
        this.hideNavbar = value;
    }
}
</script>

<style scoped>

#nav-item {
    
}

.button {
    border: 2px solid black;
    cursor: pointer;
}

.logo {
    margin-top: 10px;
}

.navbar-item img {
    max-height: 2rem;
    /*background-image: url("../assets/images/woodchip1.png")*/
}
#nav {
    height: 20px;
    padding-left: 1rem;
    padding-right: 1rem;
    position: sticky;
    width: 100%;
    top: 0;
    background-color: rgba(210, 218, 230, 0.425);
    opacity: 1.0;
}

.menu-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 70px;
}

.menu-button {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    margin-left: 25px;
}

#nav-bar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
