<template>
    <!--div class="selector chooser-container"-->
      <div class="--mt15">
        
        <div class="--mt63" id="no-results" v-if="filteredPuzzles.length < 1">
            <span>No puzzles match that name. </span>
            <br>
            <!--span style="width: 625px">Click <a @click="sendEmail()">here</a> to have you puzzle added to the list.</span-->
        
        </div>
        
        <!-- RESULTS BLOCK -->
        <div class="search-results" v-if="filteredPuzzles.length > 0">
            <!--div class="flickr-image" v-for="puzzle in filterPuzzles()"--->
            <div class="button-div"
                 v-for="puzzle in filteredPuzzles"
                 :key="puzzle.imgName">
                <!--div class="thumbnail"-->
                        <!--img :src="'@/assets/images/' + puzzle.imageName"  style="width: 100%;" -->
                        <button class="bg-washed-blue b--near-white br2 shadow grow" type="button" id="thumb-button" v-on:click="handleButtonClick(puzzle.name)">
                            <PuzzleImageThumb
                                :height="puzzle.height"
                                :width="puzzle.width"
                                :pixelRatio="pixelRatio"
                                :imageName="puzzle.thumbName"
                                :catagory="puzzle.catagory"
                            />
                            <br>
                            {{ puzzle.name }}
                        </button>
                        
                <!--div-->
            </div>
    </div>

    
    </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { Vue, Watch } from "vue-property-decorator";
import * as mut from "@/store/modules/MutationNames";
import PuzzleImageThumb from "@/components/puzzles/PuzzleImageThumb.vue"
import Image from "@/models/image"
import 'tachyons'

//Implemented with https://codepen.io/tomascherry/pen/GrgbzQ
// https://levelup.gitconnected.com/display-images-like-google-and-flickr-with-vue-js-e4cb8159ecfa
@Component({
    components: {
        PuzzleImageThumb
    }
})
export default class PuzzleSelector extends Vue {
    filteredPuzzles!: Array<Image>;
    images = new Array<typeof Image>();
    allPuzzles = this.$store.getters.puzzleInventory
    isInitialPuzzleSetup = this.$store.getters.isInitialPuzzleSetup
    search = '';
    pixelRatio = this.$store.getters.pixelRatio / 2
    isChosen = false;

  created() {
    //console.log("Select Puzzle has been created ")
    this.filteredPuzzles = this.allPuzzles
  }

  handleCancelClick() {
      //console.log("Cancel Puzzle popup has been clicked.")
      this.closeWindow();
  }

  async handleButtonClick(s) {
      //This reset is to cover Change puzzle scenario
      this.$store.commit(mut.SET_IS_PUZZLE_LOADED, false)
      this.$store.commit(mut.SET_SELECTED_PUZZLE, s)
      this.$store.commit(mut.DISABLE_NEXT_BUTTON, false)
       //Make sure the next button is exposed
      window.scrollTo(0, document.body.scrollHeight);
      this.$forceUpdate();
  }

  closeWindow() {
      this.$emit("closePuzzleSelector")
  }

  

  @Watch('$store.getters.puzzleSearchText')
   onSearchChange(value: string, oldValue: string) {
        this.filterPuzzles(value);
    }

  filterPuzzles(value: string) {
        let puzzles = new Array<Image>();
        this.allPuzzles.forEach(puzzle => {
            let lcPuzzle = puzzle.name.toLowerCase();
            let lcValue = value.toLowerCase();
            if (lcPuzzle.includes(lcValue)) {
                puzzles.push(puzzle)
            }
        });
        this.filteredPuzzles = puzzles;
        this.$forceUpdate();
  }

}


</script>

<style scoped >
::-webkit-scrollbar {
  -webkit-appearance: scrollbartrack-vertical;
  width: 7px;
  background-color: rgba(0, 0, 0, 0.048);
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
    .chooser-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: white;
    }


    .search-results {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow-y: scroll;
        height: 335px;
    }
</style>
