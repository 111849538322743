<template>
        <div class="popup-window flex-centered-col">
                <div class="frame-container">
                    <DoubleMatPuzzleFrame  
                            :puzzle="selectedPuzzle"
                      />
            </div> 
            <b-button v-if="selectedPuzzle" 
                      class="outlined-button" 
                      style="margin-top: 100px"
                      outlined 
                      @click="closeFullScreen()"
            >
                        <strong>Close</strong>
                </b-button>   
        </div>
</template>
<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import DoubleMatPuzzleFrame from  "@/components/puzzles/DoubleMatPuzzleFrame.vue"

@Component({
    components: {
        DoubleMatPuzzleFrame
    }
})
export default class FullScreenPreview extends Vue {
    @Prop()
    matImagePath;

    @Prop()
    frameHeightPixels;

    @Prop()
    frameWidthPixels;

    @Prop()
    selectedPuzzle;

    @Prop()
    stainImage;
    
    created() {
        console.log("Created FullScreenPreview")
        console.log("matImagePath:" + this.matImagePath)
        console.log("frameHeightPixels: " + this.frameHeightPixels)
        console.log("frameWidthPixels: " + this.frameWidthPixels)
        console.log("selectedPuzzle: " + this.selectedPuzzle.name)

    }

    closeFullScreen() {
        this.$emit("closeFullScreen")
    }

    
}
</script>

<style scoped>

.frame-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.img {
  box-sizing: content-box;
  object-fit: cover;
}



</style>