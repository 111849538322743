<template>
    <button 
        class="button"
        :style="'width: ' + buttonWidth"
        @click="handleClick()"
    >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,12H19C18.47,16.11 15.72,19.78 12,20.92V12H5V6.3L12,3.19M12,1L3,5V11C3,16.55 6.84,21.73 12,23C17.16,21.73 21,16.55 21,11V5L12,1Z" /></svg>
        <span style="margin-left: 7px">
            Privacy Policy
        </span>
    </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";


@Component({
    
})
export default class SecruityButton extends Vue {

    @Prop()
    disabled!: boolean;

    @Prop()
    buttonText!: boolean;

    @Prop()
    buttonWidth!: string;

    handleClick() {
        this.$emit('securityClick')
    }

}
</script>

<style scoped>

</style>
