<template>
   <div class="configure-button-content">
      <button
        v-on:click="handleClick()"
        class="edit-button"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/>
        </svg>
      </button>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from 'vue-property-decorator'

@Component({
})
export default class PencilButton extends Vue {

    @Prop()
    propertyId!: string;

    @Prop()
    propertyType!: string;

    handleClick() {
        let eventName = this.propertyType + "Click"
        this.$emit(eventName, this.propertyId)
    };
}
</script>

<style scoped>

/* Todo Merge these two edit-button styles*/
.edit-button {
    box-sizing: border-box;
    font-family: Arial,Segoe UI,Tahoma,Geneva,Verdana,sans-serif;
    font-size: .75rem;
    border: .05rem solid transparent;
    display: inline-block;
    padding: .25rem .1rem;
    margin: 0 .5rem;
    width: 60px;
    color: white;
    background-color: #000;
  }

.edit-button {
  float: right;
  margin-top: .5rem;
  margin-right: 0.875rem;
  padding: 0px;
  border: none;
  background-color: white;
}

.edit-button:hover {
  cursor: pointer;
}

.configure-text {
  float: right;
  font-size: 0.75rem;
  margin-top: .375rem;
  margin-left: .7rem
}

.edit-pencil {
  height: 1.5rem;
}

.configure-button-content {
  display: flex;
  flex-direction: column;

}
</style>