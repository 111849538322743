<template>
    <img  class="puzzle-img" @load="onImgLoad" :src="imagePath" :style="puzzleStyle"/>
</template>
<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import * as mut from "@/store/modules/MutationNames";


@Component({
    components: {
    }
})
export default class PuzzleImageFramed extends Vue {
    hasBevelBorder!: boolean
    
    @Prop()
    imageName;

    @Prop()
    catagory;

    @Prop()
    height;

    @Prop()
    width;

    @Prop()
    pixelRatio;

    created() {
        this.hasBevelBorder = this.$store.getters.isMatWithCore
    }


    get imagePath() {
        try {
            require(`./images/${this.catagory}/${this.imageName}`)
        }
        catch (e) {
            if (!this.imageName.includes("thumb")) {
                alert("missing puzzle image: " + this.imageName)
            }
        }
        let path = "img/" + this.imageName
        //console.log("imagePath: " + path)

        return path
    }

    get puzzleStyle() {

        let heightPx = this.height * this.pixelRatio + 'px'
        let widthPx = this.width * this.pixelRatio + 'px'
        let sty = "height: " + heightPx + "; width: " + widthPx + ";";
        if (this.hasBevelBorder) {
            sty = sty + "border: 3px solid white;"
        }
        //console.log("puzzle image style: " + sty)
        return sty
    }


    onImgLoad() {
        this.$store.commit(mut.SET_IS_PUZZLE_LOADED, true)
    }


}
</script>

<style scoped>



.img {
  box-sizing: content-box;
  object-fit: cover;
}


</style>