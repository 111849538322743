<template>
    <b-button 
        class="outlined-button"
        :style="'width: 110px'"
        :disabled="disabled"
        @click="handleClick()"
    >
    
        <div class="flex-row-start">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"
            style="margin-top: 0px">
                <path d="M22,3H5A2,2 0 0,0 3,5V9H5V5H22V19H5V15H3V19A2,2 0 0,0 5,21H22A2,2 0 0,0 24,19V5A2,2 0 0,0 22,3M7,15V13H0V11H7V9L11,12L7,15M20,13H13V11H20V13M20,9H13V7H20V9M17,17H13V15H17V17Z" />
            </svg>
            <span style="margin: 0 0 0 10px;">
                <strong>Next</strong>
            </span>
        </div>
    </b-button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";


@Component({
    
})
export default class NextButton extends Vue {

    @Prop()
    disabled!: boolean;

    handleClick() {
        this.$emit("nextButtonClick")
    }

}
</script>

<style scoped>

</style>
