import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/tabs/Home'
import Ideas from '@/components/tabs/Ideas'
import Samples from '@/components/tabs/Samples'
import Instructions from '@/components/tabs/Instructions'
import About from '@/components/tabs/About'
import Purchase from '@/components/tabs/Purchase.vue'
import Demo from '@/components/tabs/Demo.vue'
import FrameBuilder from '@/components/tabs/frameBuilder/FrameBuilder.vue'
import StainedGlassBuilder from '@/components/tabs/StainedGlassBuilder.vue'
import Contact from '@/components/tabs/Contact.vue'
import PrivacyPolicy from '@/components/tabs/PrivacyPolicy.vue'
import MatInventory from '@/components/tabs/hidden/MatInventory.vue'
import Mantra from '@/components/tabs/hidden/Mantra.vue'
import MatTypeInfo from '@/components/tabs/hidden/MatTypeInfo.vue'


Vue.use(Router)

export default new Router({
  routes: [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/purchase',
        name: 'Buy Now',
        component: Purchase
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/builder',
        name: 'Frame Builder',
        component: FrameBuilder
    },
    {
      path: '/stained-glass',
      name: 'Stained Glass',
      component: StainedGlassBuilder
  },
    {
        path: '/samples',
        name: 'Samples',
        component: Samples
    },
    {
        path: '/demo',
        name: 'Demo',
        component: Demo
    },
    {
        path: '/instructions',
        name: 'instructions',
        component: Instructions
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/ideas',
        name: 'Ideas',
        component: Ideas
    },
    {
        path: '/matinventory',
        name: 'Mat Inventory',
        component: MatInventory
    },
    {
        path: '/mantra',
        name: 'Mantra',
        component: Mantra
    },
    {
        path: '/matinfo',
        name: 'Mat Type Info',
        component: MatTypeInfo
    },
    {
        path: '/policy',
        name: 'Privacy Policy',
        component: PrivacyPolicy
    }
]
})
