<template>
    <div class="popup-window">
        <div class="flex-row-centered">
        <div class="popup-container" :style="styleWidth">
       
        <div class="info-grid-container">
            <span class="title">{{ infoTitle }}</span>
            <CloseButton class="closer"
                        :propertyType="propertyType"
                        v-on="$listeners"
                />
            
            <span class="desc --ml20">{{ description }} </span>
    
            <img class="puzzle-img info-image" :src="imagePath" :style="style"/>
            
        </div>
    </div>
    </div>
    </div>
</template>
<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import * as mut from '@/store/modules/MutationNames';
import CloseButton from "@/components/buttons/CloseButton.vue"


@Component({
    components: {
        CloseButton
    }
})
export default class InfoPopup extends Vue {
    styleWidth!: string;

    @Prop()
    infoTitle!: string;

    @Prop()
    description!: string;

    @Prop()
    propertyType!: string;

    @Prop()
    imageName!: string;

    @Prop()
    width!: string;

    created() {
        this.styleWidth = this.width + "px;"
        this.$forceUpdate()
    }

    get imagePath() {
        require('./images/' + this.imageName)
        let path = 'img/' + this.imageName
        return path
    }

    get style() {
        let heightPx =  '550px'
        let widthPx = '800px'
        //console.log("getting style for " + this.propertyType)
        if (this.propertyType.includes("MatType")) {
            widthPx = '1200px'
        }
        
        return "height: " + heightPx + "; width: " + widthPx;
    }
}
</script>

<style scoped>



.img {
  box-sizing: content-box;
  object-fit: cover;
}

.info-container {
    border-color: gray;
    border-radius: 8px;
    
}

.info-grid-container {
  display: grid;
  column-gap: 30px;
  row-gap: 10px;
  background-color: white;
  grid-template-columns: 5px 10px auto 40px;
  grid-template-rows: 2px 5px auto auto auto;
  margin: 0 1rem 1rem 1rem;
  grid-template-areas: 
    ".  .     .           . "
    ".  .     .           closer "
    ".  title title       . "
    ".  .     desc        ."
    ".  .     info-image  .";
}

.title {
    grid-area: title;
}

.closer {
    grid-area: closer;
}

.desc {
    grid-area: desc;
}

.info-image {
    grid-area: info-image;
}



</style>