<template>
<div>
    <div class="flex-row-start">
        <span style="margin-left: 18rem;"><strong>Single Mat</strong></span>  
        <span style="margin-left: 34rem;"><strong>Double Mat</strong></span>  
    </div> 
    <div class="flx-row-space-even">
        <img class="mat-img " src="img/singleMat.jpeg">      
        <img class="mat-img " src="img/doubleMatCream.jpeg">           
    </div> 
    
    <div class="flex-row-center  --mt15" >
        <img class="mat-img " style="margin-left: 480px;" src="img/crescent.jpeg">      
    </div>
</div> 
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { Vue, Watch } from "vue-property-decorator";
import * as mut from "@/store/modules/MutationNames";
import MatImage from "@/components/mat/MatImage.vue"
import InfoButton from "@/components/buttons/InfoButton.vue"

@Component({
    components: {
        MatImage,
        InfoButton
    }
})
export default class MatTypeSelector extends Vue {


  created() {
      require(`./images/crescent.jpeg`)
      require(`./images/doubleMatCream.jpeg`)
      require(`./images/singleMat.jpeg`)
  }


}



</script>

<style scoped >

.color-selector-title {
    margin: 30px 15px 0 40px;
}

.color-selector {
  z-index: 1; /* Sit on top */
  margin-top: 10px;
  background-color: white;
  border: 1px solid gray;
}

.popup-container.mat_width_info {
  margin-left: 100px;
  width: 700px;
  height: 200px;
}

    .radio-container {
        height: inherit;
        width: 100%;
    }

    .page-title {
        margin: 20px 10px;
    }
    .chooser-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        width: 80%;
        height: 80%;
        margin: 4rem 4rem 4rem 4rem;
    }
    .radio-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        width: 80%;
        height: 80%;
        margin: 4rem 4rem 4rem 4rem;
    }

    
    .search-results {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    

    .blocker {
        display: block;
        position: absolute; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 6.25rem; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    }

    /* variables */
@text-color: #222222;
@link-color: #128fdc;
@tag-color: #128fdc;

/*styles */



/* utilities 
.center {
  text-align: center;
}

*, *:before, *:after {
  box-sizing:  border-box !important;
}*/
</style>
