<template>
  <div class="flex-centered-col" v-if="true">
    <h1 class="header">A sampling of puzzle frames built at Gunbarrel Studio</h1>
    <!--span class="header">Click on image to pause</span>
    <span class="header"> Scroll down to read frame details beneath image:</span-->
    <b-carousel :arrow=false
                :pause-info=false
    >
        <b-carousel-item 
            class="title" 
            style="max-width: 700px;" 
            v-for="sample in sampleCarousel" :key="sample.color"
        >
            
            <div class="flex-centered-col">
            <img class="puzzle-img" :src="'img/' + sample.imgName" />
            <!--b-table v-if="sample.wood"
                :data="sample.tableRows"
                :columns="columns"
                :selected="null"
            >
            </b-table-->
            </div>
        </b-carousel-item>
    </b-carousel>
    <!--div class="sample-container">
      <img class="puzzle-img" src='img/flatirons_frame.jpeg'/>
    </div>
    <div class="sample-container">
      <img class="puzzle-img" src='img/terrace_frame.png'/>
    </div>
    <div class="sample-container">
      <img src="../../assets/images/framed_puzzle.jpeg"/>
    </div>
    <div class="sample-container">
      <img class="puzzle-img" src='img/van_gogh_frame.png'/>
    </div-->
    
</div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";

@Component({
    components: {
    }
})
export default class Samples extends Vue {
    sampleCarousel = this.$store.getters.samplesInventory
    columns = [
        {
            field: "itemAttribute",
            label: "",
            width: 250
        },
        {
            field: "selectedValue",
            label: "",
            width: 300
        }
    ]
    

  created() {
      this.sampleCarousel.forEach(sample => {
          require(`../../assets/images/samples/${sample.imgName}`)
      });     
      
  }


}
</script>


<style scoped>
.sample-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }


.header {
    margin-bottom: 5px;
}

</style>
