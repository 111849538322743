<template> 
    <!--div class="one-half column flex-row-start"
    -->
        <!--b-tooltip  v-if="!search  && isInitialPuzzleSetup" 
                    class="animated-2s-fade-in"
                    :type="'is-warning'"
                    :label="'Enter words from name of your puzzle to filter the puzzle options that are displayed ...'"
                    position="is-top"
                    always
                    multilined
        /-->
        <input type="text" 
                v-model="search" 
                debounce="500" 
                class="u-full-width " 
                placeholder="Puzzle Name Filter"
         />
    
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { Vue, Watch } from "vue-property-decorator";
import * as mut from "@/store/modules/MutationNames";
import 'tachyons';

//Implemented with https://codepen.io/tomascherry/pen/GrgbzQ
// https://levelup.gitconnected.com/display-images-like-google-and-flickr-with-vue-js-e4cb8159ecfa
@Component({
    components: {
    }
})
export default class PuzzleSearchTextBox extends Vue {
    
    isInitialPuzzleSetup = this.$store.getters.isInitialPuzzleSetup
    search = '';
    isChosen = false;

  

  @Watch('search')
    onSearchChange(value: string, oldValue: string) {
        this.$store.commit(mut.SET_PUZZLE_SEARCH_TEXT, value);
    }


}


</script>

<style scoped >

    .page-title {
        margin: 20px 10px;
    }
    .chooser-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        width: 80%;
        height: 80%;
        margin: 4rem 4rem 4rem 4rem;
    }


    .search-results {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow-y: scroll;
        height: 400px;
        
    }
</style>
