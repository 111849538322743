<template>
  <div class="about" v-if="true">
    <h1  style="margin: 10px 5px 10px 50px">Welcome to Gunbarrel Studio...</h1>
    
    <div style="margin-left: 10px">
        <img src="../../assets/images/PuzzlePieces.png"/>
    </div>
    <span>@ Gunbarrel Studio we are <strong>passionate</strong> about having fun building <a href="https://www.libertypuzzles.com/">Liberty Puzzles</a></span>
    <span>after the fun is done hanging the fun on the wall </span>
    <span>then taking it down and having fun again<strong>!!</strong></span>
    <br>
    <span><strong><em>Can you jig it?</em></strong></span>
    <br>
    <!--div style="margin-left: 10px">
        <img src="../../assets/images/garage.png"/>
    </!--div-->
  </div>
</template>

<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";

@Component({
    components: {
    }
})
export default class About extends Vue {
    showAbout = false;
  created() {
      //console.log("About page has been created.")
      this.showAbout = this.$store.getters.showAbout
  }


  @Watch('$store.getters.showAbout')
    onPageChange(value: boolean, oldValue: boolean) {
        //console.log("about onPageChange: ", value)
        this.showAbout = value;
        this.$forceUpdate()
    }
}
</script>


<style scoped>
.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
</style>
