// RestStore
export const SET_FRAME_COLOR = "muyFrameColor"
export const SET_TOP_MAT_WIDTH = "mutTopMatWidth"
export const SET_FRAME_BOARD_WIDTH = "mutFrameBoardWidth"
export const SET_FRAME_INVENTORY = "mutSetFrameInventory"
export const SET_SELECTED_PUZZLE = "mutSetSelectedPuzzle"
export const SET_TOP_MAT_COLOR = "mutSetTopMatColor"
export const SET_BOTTOM_MAT_COLOR = "mutSetBottomMatColor"
export const SET_IS_MAT_CONFIGURED = "mutSetIsMatConfigured"
export const SET_SHOW_ABOUT = "mutSetShowAbout"
export const SET_SHOW_BUILDER = "mutSetShowBuilder"
export const SET_SELECTED_STAIN = "mutSetSelectedStain"
export const SET_SELECTED_WOOD = "mutSetSelectedWood"
export const SET_MAT_INVENTORY = "mutSetMatInventory"
export const SET_MAT_SIZES = "mutSetMatSizes"
export const SET_PINE_STAINS = "mutSetPineStains"
export const SET_OAK_STAINS = "mutSetOakStains"
export const SET_MAPLE_STAINS = "mutSetMapleStains"
export const SET_POPLAR_STAINS = "mutSetPoplarStains"
export const SET_IS_MAT_WIDTH_SELECTED = "mutSetIsMatWidthSelected"
export const SET_IS_MAT_COLOR_SELECTED = "mutSetIsMatColorSelected"
export const SET_IS_INITIAL_PUZZLE_SETUP = "mutSetIsInitialPuzzleSetup"
export const SET_IS_FRAME_CONFIGURED = "mutSetIsFrameConfigured"
export const PUSH_TO_CART = "mutPushToCart"
export const SET_ORDER_ITEM = "mutReplaceOrderItem"
export const RESET_PUZZLE = "mutResetPuzzle"
export const SET_SELECTED_STAIN_IMAGE_NAME = "mutSetSelectedStainImageName"
export const REMOVE_ORDER_FROM_CART = "mutRemoveItemFromCart"
export const EMPTY_CART = "mutEmptyCart"
export const SET_EMAIL_REQUEST_TYPE = "mutEmailRequestType"
export const SET_BUILD_STEP = "mutBuildStep"
export const INCREMENT_BUILD_STEP = "mutIncrementBuildStep"
export const SET_MAT_TYPE = "mutMatType"
export const SET_BOTTOM_MAT_REVEAL = "mutBottomMatReveal"
export const SET_BOTTOM_MAT_PIXELS = "mutSetBottomMatPixels"
export const SET_PUZZLE_INVENTORY = "mutSetPuzzleInventory"
export const SET_PUZZLE_SEARCH_TEXT = "mutSetPuzzleSearchText"
export const SET_SELECTION_WINDOW = "mutSetSelectionWindow"
export const SET_SHOW_NEXT_BUTTON = "mutSetShowNextButton"
export const DISABLE_NEXT_BUTTON = "mutDisableNextButton"
export const SET_IS_PUZZLE_LOADED = "mutSetIsPuzzleLoaded"
export const SET_ORDER_NUMBER = "mutSetOrderNumber"
export const TOGGLE_SHOW_REACT_SEND_EMAIL = "mutToggleShowReact"
export const SET_IS_CANCELED = "mutSetIsCanceled"