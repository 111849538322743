<template>
  <div class="flex-centered-col --ml25 --mr25 --mb12" v-if="true">
    <!--iframe src="https://www.youtube.com/embed/ZF8SjvA2DZU" id="gbsDemo"></!--iframe>
    <video src="???"></video>-->
    <br>
    <object data='https://www.youtube.com/embed/MltdoXpdY2U' id="gbsDemo"/>
</div>
</template>

<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";

@Component({
    components: {
    }
})
export default class Demo extends Vue {

  
}
</script>


<style scoped>
#gbsDemo {
        background: white;
        width: 100%;
        height: 800px;
        resize: both;
        overflow: auto;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
</style>
