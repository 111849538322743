<template>
    <div class="setting-container"
        :class="{disabled: disable}"
    >
    <div v-if="isMaxLength">
      <p >For longer questions or comments, please use a standard email </p>
      <p>platform to compose and send email to jon@gunbarrelstudio.com</p>
      <br>
    </div>
        <div class="setting-content">
            <span class="editor-row-title">{{settingTitle}}</span>
            <b-field>
                <b-input
                    :class="{dirty: $v.localnextValue.$dirty && !$v.localnextValue.$invalid, invalid: $v.localnextValue.$invalid}"
                    :id="'sv-' + propName"
                    name="input"
                    type="textarea"
                    maxlength="500"
                    :style="'width: 30rem'"
                    :readonly="readonly"
                    v-model="localnextValue"
                    @input.native="setValue($event.target.value)"
                />
            </b-field>
        </div>
        <div class="error-text" v-if="!$v.localnextValue.required">
            {{ settingTitle }} Required
        </div>
   </div> 
</template>

<script lang="ts">
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import {required, requiredIf} from 'vuelidate/lib/validators'
Vue.use(Vuelidate as any);

export default Vue.extend({
  name: 'TextSetting',
  props: {settingTitle: String,
          propName: String,
          settingValue: String,
          isRequired: Boolean,
          readonly: String,
          disable: Boolean
  },
  data() {
    return {
      localnextValue: this.settingValue,
      //this is a hack to get around requred validator not being used when using requiredIf
      hack: true,
      isMaxLength: false
    }
  },
  validations () {
    return {
      hack: {
        required
      },
      localnextValue: {
         required: requiredIf(function(this: any) {
                    return this.isRequired;
                }) 
      }
    }
  },
  methods: {
    setValue(newValue: string) {
      this.$v.localnextValue.$touch()
      const eventName = "update" + this.propName
      //console.log("emitting " + eventName + " to value: " + newValue)
      this.$emit(eventName, newValue)
      if (newValue.length > 499 ) {
        this.isMaxLength = true
      }
      else {
        this.isMaxLength = false
      }
    }
  },
  computed: {
    isValid: function() {
      return !this.$v.localnextValue.$invalid
    },
    isDirty: function() {
      return this.$v.localnextValue.$dirty
    },
    fieldWidth: function () {
      if (String(this.settingValue).length > 21) {
        return `${String(this.settingValue).length + 2}ch`
      }
      return "21ch"
    }
  },
  watch: {
    isValid(val) {
      const eventName = "setValidStatus" + this.propName
      //console.log("emitting " + eventName + " to value: " + val)
      this.$emit(eventName, val)
    },
    isDirty(val) {
      const eventName = "setDirtyStatus" + this.propName
      //console.log("emitting " + eventName + " to value: " + val)
      this.$emit(eventName, val)
    },
    settingValue(val) {
        this.localnextValue = val
    }

  }
})
</script>

<style lang="scss" scoped>
/* Disable text box outline when it gets clicked on */
*:focus {
    outline: none;
}


</style>