<template>
  <div class="home">
      <span style="margin: 10px 0 0 0">3D Relief Map using Shaper</span>
      <span>Quote from the cactus sign</span>
      <hr>
      <button class="item-button" type="button" v-on:click="handleMatColorButtonClick()">
        <img src="../../assets/images/ideas/booshee1.jpeg"/>
      </button>
      <button class="item-button" type="button" v-on:click="handleMatColorButtonClick()">
        <img src="../../assets/images/ideas/v-palmSprings-mirror1.jpeg"/>
      </button>
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";


@Component({
    components: {
    }
})
export default class Home extends Vue {
  created() {
      console.log("Home page has been created.")
    
        require(`../../assets/images/ideas/booshee1.jpeg`)
        let path = "img/booshe1.jpeg"
        //console.log("imagePath: " + path)
        return path

  }


  onPuzzleClick() {
      this.$router.push( "/demo");
  }
}
</script>

<style scoped>

.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
