<template>
    <button
        class="close-button"
        v-on:click="handleClick()"
    >
        <svg xmlns="http://www.w3.org/2000/svg" height="23px" viewBox="0 0 24 24" width="23px" fill="#aaaaa">
            <path d="M0 0h24v24H0V0z" fill="none" opacity=".87"/>
            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z"/>
        </svg>
    </button>
</template>


<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from 'vue-property-decorator'
@Component({
})
export default class CloseButton extends Vue {
    @Prop()
    propertyId!: string;

    @Prop()
    propertyType!: string;

    handleClick() {
        let eventName = this.propertyType + "Click"
        //console.log("emitting " + eventName)
        this.$emit(eventName, this.propertyId)
    };
}
</script>

<style scoped>
.close-button {
    color: black;
    float: right;
    border: none;
    background-color: rgba(54, 54, 54, 0.0); /* Dark Gray w/ opacity */
}
.close-button:hover {
  cursor: pointer;
}
</style> 