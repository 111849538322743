const puzzle_inventory = [
	{
		imgName: "friends_that_row_together.jpg",
		thumbName: "friends_that_row_together_thumb.jpg",
		name: "Friends That Row Together",
		catagory: "americana",
		height: 8.75,
		width: 12.75
	},
	{
		imgName: "vogue_americana_number.jpg",
		thumbName: "vogue_americana_number_thumb.jpg",
		name: "Vogue Americana Number",
		catagory: "americana",
		height: 17.0,
		width: 12.75
	},
	{
		imgName: "grand_canyon_falls.jpg",
		thumbName: "grand_canyon_falls_thumb.jpg",
		name: "Grand Canyon Falls",
		catagory: "americana",
		height: 14.75,
		width: 9.75
	},
	{
		imgName: "rocky_mountain_landscape.jpg",
		thumbName: "rocky_mountain_landscape_thumb.jpg",
		name: "Rocky Mountain Landscape",
		catagory: "americana",
		height: 14.75,
		width: 22.0
	},
	{
		imgName: "outer_banks.png",
		thumbName: "outer_banks.png",
		name: "Outer Banks of North Carolina",
		catagory: "americana",
		height: 19.75,
		width: 14.75
	},
	
	{
		imgName: "close_range.jpg",
		thumbName: "close_range_thumb.jpg",
		name: "Close Range",
		catagory: "americana",
		height: 21.5,
		width: 12.75
	},
	{
		imgName: "ought_six.jpg",
		thumbName: "ought_six_thumb.jpg",
		name: "Ought Six",
		catagory: "americana",
		height: 16.5,
		width: 13.75
	},
	{
		imgName: "a_bevy_of_wild_west_women.jpg",
		thumbName: "a_bevy_of_wild_west_women_thumb.jpg",
		name: "A Bevy of Wild West Women",
		catagory: "americana",
		height: 11.75,
		width: 17.25
	},
	{
		imgName: "green_river_cliffs.jpg",
		thumbName: "green_river_cliffs_thumb.jpg",
		name: "Green River Cliffs",
		catagory: "americana",
		height: 9.25,
		width: 23.75
	},
	{
		imgName: "landers_peak.jpg",
		thumbName: "landers_peak_thumb.jpg",
		name: "Lander's Peak",
		catagory: "americana",
		height: 12.75,
		width: 19.75
	},
	{
		imgName: "starrucca_viaduct_pennsylvania.jpg",
		thumbName: "starrucca_viaduct_pennsylvania_thumb.jpg",
		name: "Starrucca Viaduct, Pennsylvania",
		catagory: "americana",
		height: 11.75,
		width: 19.75
	},
	{
		imgName: "william_groppers_american_folklore.jpg",
		thumbName: "william_groppers_american_folklore_thumb.jpg",
		name: "William Gropper's American Folklore",
		catagory: "americana",
		height: 14.5,
		width: 21.75
	},
	{
		imgName: "a_dash_for_the_timber.jpg",
		thumbName: "a_dash_for_the_timber_thumb.jpg",
		name: "A Dash for the Timber",
		catagory: "americana",
		height: 11.75,
		width: 21.5
	},
	{
		imgName: "mt._corcoran.jpg",
		thumbName: "mt._corcoran_thumb.jpg",
		name: "Mt. Corcoran",
		catagory: "americana",
		height: 15.0,
		width: 23.75
	},
	{
		imgName: "denver_and_rio_grande_western_railroad.jpg",
		thumbName: "denver_and_rio_grande_western_railroad_thumb.jpg",
		name: "Denver & Rio Grande Western Railroad",
		catagory: "americana",
		height: 11.75,
		width: 21.0
	},
	{
		imgName: "the_american_national_game_of_baseball.jpg",
		thumbName: "the_american_national_game_of_baseball_thumb.jpg",
		name: "The American National Game of Baseball",
		catagory: "americana",
		height: 12.75,
		width: 18.5
	},
	{
		imgName: "buffalo_bills_wild_west.jpg",
		thumbName: "buffalo_bills_wild_west_thumb.jpg",
		name: "Buffalo Bill's Wild West",
		catagory: "americana",
		height: 11.75,
		width: 9.75
	},
	{
		imgName: "rambles_through_our_country.jpg",
		thumbName: "rambles_through_our_country_thumb.jpg",
		name: "Rambles Through Our Country",
		catagory: "americana",
		height: 14.25,
		width: 21.75
	},
	{
		imgName: "american_express_train.jpg",
		thumbName: "american_express_train_thumb.jpg",
		name: "American Express Train",
		catagory: "americana",
		height: 12.75,
		width: 18.25
	},
	{
		imgName: "buffalo_bill_the_great_train_holdup.jpg",
		thumbName: "buffalo_bill_the_great_train_holdup_thumb.jpg",
		name: "Buffalo Bill: The Great Train Holdup",
		catagory: "americana",
		height: 12.75,
		width: 19.0
	},
	{
		imgName: "declaration_of_independence.jpg",
		thumbName: "declaration_of_independence_thumb.jpg",
		name: "Declaration of Independence",
		catagory: "americana",
		height: 12.75,
		width: 19.5
	},
	{
		imgName: "large_americas-making-XL-watermark.jpeg",
		thumbName: "map_of_americas_making_thumb.jpg",
		name: "Map of America's Making",
		catagory: "americana",
		height: 16.0,
		width: 22.0
	},
	{
		imgName: "spirit_of_76.jpg",
		thumbName: "spirit_of_76_thumb.jpg",
		name: "Spirit of '76",
		catagory: "americana",
		height: 15.75,
		width: 12.75
	},
	{
		imgName: "storm_in_the_rocky_mountains.jpg",
		thumbName: "storm_in_the_rocky_mountains_thumb.jpg",
		name: "Storm in the Rocky Mountains",
		catagory: "americana",
		height: 13.75,
		width: 21.0
	},
	{
		imgName: "yacht_race_off_boston_light.jpg",
		thumbName: "yacht_race_off_boston_light_thumb.jpg",
		name: "Yacht Race Off Boston Light",
		catagory: "americana",
		height: 12.75,
		width: 17.0
	},
	{
		imgName: "fourth_of_july_1916.jpg",
		thumbName: "fourth_of_july_1916_thumb.jpg",
		name: "Fourth of July, 1916",
		catagory: "americana",
		height: 12.75,
		width: 9.25
	},
	{
		imgName: "july_4_1776.jpg",
		thumbName: "july_4_1776_thumb.jpg",
		name: "July 4, 1776",
		catagory: "americana",
		height: 8.25,
		width: 12.75
	},
]

export default puzzle_inventory