<template>
    <button
        class="info-button"
        v-on:click="handleClick()"
    >
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
        </svg>
    </button>
</template>


<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from 'vue-property-decorator'
@Component({
})
export default class InfoButton extends Vue {
    @Prop()
    propertyId!: string;

    @Prop()
    propertyType!: string;

    handleClick() {
        let eventName = this.propertyType + "Click"
        //console.log("emiting " + eventName)
        this.$emit(eventName, this.propertyId)
    };
}
</script>

<style scoped>
.info-button {
    box-sizing: border-box;
    font-family: Arial,Segoe UI,Tahoma,Geneva,Verdana,sans-serif;
    font-size: .75rem;
    width: 30px;
    color: white;
    background-color: #000;
    float: right;
    margin-top: 0;
    margin-right: 0;
    padding: 0px;
    border: none;
    background-color: white;
}
.info-button:hover {
  cursor: pointer;
}
.configure-text {
  float: right;
  font-size: 0.75rem;
  margin-top: .375rem;
  margin-left: .7rem
}
.info-icon {
  height: 1.5rem;
}
.configure-button-content {
  display: flex;
  flex-direction: column;
}
</style> 