<template>
    <button 
        class="button"
        :style="'width: ' + buttonWidth"
        :disabled="disable"
        @click="handleClick()"
    >
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M4 6.03L11.5 9.25L4 8.25L4 6.03M11.5 14.75L4 17.97V15.75L11.5 14.75M2 3L2 10L17 12L2 14L2 21L23 12L2 3Z" /></svg>
        <span style="margin-left: 7px">
            Send
        </span>
    </button>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";


@Component({
    
})
export default class SendEmailButton extends Vue {

    @Prop()
    disable: boolean;

    @Prop()
    buttonText!: boolean;

    @Prop()
    buttonId!: string;

    @Prop()
    buttonWidth!: string;


    handleClick() {
        this.$emit('sendClick')
    }



    @Watch('disable')
    onDisableChange(value: boolean, oldValue: boolean) {
        //console.log("send button watcher : " + value)
        //this.isFrameConfigured = value;
        
    }


}
</script>

<style scoped>

</style>
