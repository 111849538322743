<template>
        <!--div class="puzzle"-->
            <img class="puzzle-img" :src="imagePath" :style="style"/>
        <!--div-->
</template>
<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";

@Component({
    components: {
    }
})
export default class MatItem extends Vue {

    
    @Prop()
    imageName;

    @Prop()
    isSelected;

    get imagePath() {
        require(`./images/${this.imageName}`)
        let path = "img/" + this.imageName
        return path
    }

    get style() {
        let heightPx =  '40px'
        let widthPx = '80px'
        return "height: " + heightPx + "; width: " + widthPx;
    }
}
</script>

<style scoped>



.img {
  box-sizing: content-box;
  object-fit: cover;
}



</style>