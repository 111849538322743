<template>
        <div id="spinner"></div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'Spinner',
  props: ['loadingText']
})
</script>

<style>

#spinner {
  position: relative;
  z-index: 1;
  width: 100px;
  height: 100px;
  margin: auto;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid  #b9cef0;
  animation: spin 2s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
