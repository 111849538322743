<template>
    <div class="chooser-container">
        <div class="flex-row-container">
            <span class="--width200px  --ml35 "><strong>Type of Wood:</strong></span>
            <div class="flex-centered-col"> 
                <CustomSelect 
                    :placeholder="placeholder"
                    :propName="'wood'"
                    :disable="false"
                    :optionKey="selectedWood"
                    :options="this.$store.getters.woodOptions"
                    @woodSelectionChange="handleWoodChange($event)"
                />
                <b-tooltip   v-if="!selectedWood  && isInitialPuzzleSetup"  
                            class="animated-2s-fade-in"
                            :type="'is-warning'"
                            :label="'Select the frame wood type ...'"
                            position="is-bottom"
                            always
                            >
                </b-tooltip>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import * as act from "@/store/modules/ActionNames";
import FrameImage from "@/components/frame/FrameImage.vue"
import CustomSelect from  "@/components/controls/CustomSelect.vue"



@Component({
    components: {
        FrameImage,
        CustomSelect
    }
})
export default class FrameWoodSelector extends Vue {
    isInitialPuzzleSetup = this.$store.getters.isInitialPuzzleSetup
    showInfo = false;
    placeholder="Select wood..."
    selectedWood = null;

    created() {
        if(this.$store.getters.buildStep > this.$store.getters.buildSteps.SELECT_WOOD_TYPE) {
            this.selectedWood = this.$store.getters.selectedWood
        }
    }
    
    handleWoodChange(e: string) {
        //console.log("selected wood handler event: ", e)
        this.$store.dispatch(act.SET_SELECTED_WOOD, e)
        this.selectedWood = e;
    }
}

</script>

<style scoped >

    .page-title {
        margin: 20px 10px;
    }

    .setting-title {
        width: 120px;
        margin-left: 10px;
    }

    .chooser-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: white;
        justify-content: flex-end;
    }

    .flex-row-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: white;
    }
</style>
