<template>
  <div class="flex-centered-col">
    <CartCheckoutButton @checkoutClick="onCheckoutClick()"/>
    <div class="order-tray" v-for="order in orders" :key="order.key" >
        <!--span>Puzzle: {{ order.title }}</!--span-->
        
            <b-table 
                :data="order.rows"
                :columns="orderItemColumns"
                :selected="null"
            >
            </b-table>
            
            <TrashButton class="--ml70"
                         :buttonText="'Remove from Cart'" 
                        :buttonWidth="'210px'"
                        :buttonId="'remove'"
                        @removeClick="removeItem(order.key)"/>
            
          <hr>
    </div>
  </div>
</template>

<script lang="ts">
import VueRouter from 'vue-router';
import * as act from '@/store/modules/ActionNames';
import * as mut from '@/store/modules/MutationNames';
import { Component, Watch } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import CartCheckoutButton from "@/components/buttons/CartCheckoutButton.vue"
import TrashButton from "@/components/buttons/TrashButton.vue"
import {CartItem, getInchesAsString} from "@/store/GbsStore"
import { DialogProgrammatic as Dialog } from 'buefy';


@Component({
    components: {
        CartCheckoutButton,
        TrashButton
    }
})
export default class Purchase extends Vue {
    cart = this.$store.getters.cart;
    orders = new Array<any>();
    orderItemColumns = this.$store.getters.orderItemColumns;
    created() {
        console.log("Purchase page has been created with cart: ", this.cart)
        this.itemizeOrders()
    }

    itemizeOrders() {
      this.orders = new Array<any>();
      this.cart.forEach(order => {  let rows =  new Array<any>();
                                    let row;
                                    let topMatColorTitle = "Mat Color";
                                    let topMatWidthTitle = "Mat Size";
                                    if (order.bottomMatReveal > 0) {
                                        topMatColorTitle = "Primary Mat Color";
                                        topMatWidthTitle = "Primary Mat Size";
                                    }
                                    row = {
                                                'id': 1,
                                                "itemAttribute": "Puzzle:",
                                                'selectedValue': order.puzzle 
                                            };
                                    rows.push(row);
                                    row = {
                                        'id': 3,
                                        "itemAttribute": topMatColorTitle,
                                        'selectedValue': order.topMatColor
                                    };
                                    rows.push(row);
                                    //below selectedValue was getInchesAsString(order.topMatWidth) but topMatWidth was undefined so I changed it to '1"'
                                    row = {
                                        'id': 4,
                                        "itemAttribute": topMatWidthTitle,
                                        'selectedValue': "1\""
                                    };
                                    rows.push(row);
                                    if (order.bottomMatReveal > 0) {
                                        row = {
                                            'id': 5,
                                            "itemAttribute": "Accent Mat Color",
                                            'selectedValue': order.bottomMatColor
                                        };
                                        rows.push(row);
                                        row = {
                                            'id': 6,
                                            "itemAttribute": "Accent Mat Reveal",
                                            'selectedValue': getInchesAsString(order.bottomMatReveal)
                                        };
                                        rows.push(row);
                                    }
                                    row = {
                                        'id': 7,
                                        "itemAttribute": "Wood:",
                                        'selectedValue': order.wood
                                    };
                                    rows.push(row);
                                    row = {
                                                'id': 8,
                                                "itemAttribute": "Stain:",
                                                'selectedValue': order.stain
                                            };
                                    rows.push(row);
                                    
            let o = {
                    key: order.key,
                    title: order.puzzle,
                    rows: rows
            }
            this.orders.push(o)
        })
    }

    async onCheckoutClick() {
        alert("Gunbarrel Studio is in the process of setting up a secure payment system and shipping options.  Thank you for your patience.")
        this.$store.commit(mut.SET_EMAIL_REQUEST_TYPE, "purchase");
        this.$router.push( "/contact");
        this.$store.dispatch(act.SAVE_ORDER_ON_SERVER);
    }

    async removeItem(key: number) {
        await this.$store.dispatch(act.REMOVE_ORDER_FROM_CART, key)
        
    }

    @Watch('$store.getters.cart')
    onIsFrameConfigToggle(value: Array<CartItem>, oldValue: Array<CartItem>) {
        //console.log("heard cart update: ", value);
        if (this.cart.length == 0) {
            this.$router.push( "/builder")
        }
        this.cart = this.$store.getters.cart;
        this.itemizeOrders()
    }
}
</script>

<style>


</style>
