<template>
  <div class="home">
      <span style="margin: 10px 0 0 0">Gunbarrel Studio partners with  <a href="https://www.bammannstainedglass.com/">Bammann Stained Glass</a> to provide custom frames</span>
      <span class="--mb12">for stained glass work.</span>
    <div style="margin-left: 10px; max-width: 1200px;">
    <hr>
    <img width="600" height="600" src="../../assets/images/HangingStainedGlassHorizontal.jpeg"/>
    <hr>
    <img width="600" height="600" src="../../assets/images/p_anderson_frame.png"/>
    <hr>
    <img width="600" height="600" src="../../assets/images/HangingStainedGlassDiagonal.jpeg"/>
    <hr>
    <img width="600" height="600" src="../../assets/images/stained_glass.jpeg"/>

    </div>
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";


@Component({
    components: {
    }
})
export default class Home extends Vue {
  created() {
      //console.log("Home page has been created.")
  }


  onPuzzleClick() {
      //this.$router.push( "/demo");
  }
}
</script>

<style scoped>

.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
