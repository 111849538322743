// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/ReadOak.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo[data-v-bd7c7788]{margin-top:10px}.navbar-item img[data-v-bd7c7788]{max-height:2rem}#nav[data-v-bd7c7788]{height:60px;padding-left:1rem;padding-right:1rem;position:sticky;width:100%;top:0;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});opacity:1}.menu-bar[data-v-bd7c7788]{flex-direction:row;justify-content:space-around;margin-top:10px;margin-bottom:10px;height:70px}#nav-bar-container[data-v-bd7c7788],.menu-bar[data-v-bd7c7788]{display:flex;align-items:center}#nav-bar-container[data-v-bd7c7788]{flex-direction:column;justify-content:center}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
