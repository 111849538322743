import { render, staticRenderFns } from "./TopMatColorLineItem.vue?vue&type=template&id=11391104&scoped=true"
import script from "./TopMatColorLineItem.vue?vue&type=script&lang=ts"
export * from "./TopMatColorLineItem.vue?vue&type=script&lang=ts"
import style0 from "./TopMatColorLineItem.vue?vue&type=style&index=0&id=11391104&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11391104",
  null
  
)

export default component.exports