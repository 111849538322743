<template>
    <div class="flex-centered-col">
        <h1><strong>Six Things Mentally Strong People Do:</strong></h1>
        <br>
        <ul>
            <li>They move on.  They don't waste time feeling sorry for themselves.</li>
            <li>They embrace change. They welcome challanges.  Chalonge!</li>
            <li>They stay happy.  They don't waste energy on things they can't contorl.</li>
            <li>They are kind, fare, and not afraid to speak up.</li>
            <li>They are willing to take calculated risks.</li>
            <li>They celebrate other peoples success. They don't resent that success.</li>
        </ul>
        <br>
        <h3>Are my thoughts usefule?  How do they behave?</h3>
        <br>
        <div class="flex-centered-col" style="width: 50%">
        <ul>
            <li>Prayer works miracles in you and through you.</li>
            <li>Satan wants to seduce you. In the simplicity of heart seek of the Most High to give you the strength to be God's children and for Satan not to shake you like the wind shakes the branches.</li>
            <li>Keep looking at my Son and follow Him towards Calvary in renunciation and fasting.</li>
        </ul>
        <br>
        <span>October 25, 2013
            "Dear children! Today I call you to open yourselves to prayer. Prayer works miracles in you and through you. Therefore, little children, in the simplicity of heart seek of the Most High to give you the strength to be God's children and for Satan not to shake you like the wind shakes the branches. Little children, decide for God anew and seek only His will, and then you will find joy and peace in Him. Thank you for having responded to my call."
        </span>
        <br>
        <span>Dear children!

Keep converting and clothe yourselves in penitential garments and in personal, deep prayer; and in humility, seek peace from the Most High. In this time of grace, Satan wants to seduce you; but you, little children, keep looking at my Son and follow Him towards Calvary in renunciation and fasting. I am with you because the Most High permits me to love you and lead you towards the joy of the heart, in faith which grows for all those who love God above all. Thank you for having responded to my call.” (February 25, 2023)</span>
        <br>
        <span>“Dear children!



Pray with me for peace,

because Satan wants war and hatred

in hearts and peoples.



Therefore, pray and sacrifice your days

by fasting and penance,

that God may give you peace.



The future is at a crossroads,

because modern man does not want God.



That is why mankind is heading to perdition.



You, little children, are my hope.



Pray with me,

that what I began in Fatima and here

may be realized.



Be prayer and witness peace in your surroundings,

and be people of peace. 



Thank you for having responded to my call.”

(January 25, 2023)</span>
        </div> 
        <br>
        <span>May 25, 2023
            Dear children! I am calling you to go into nature and to pray for the Most High to speak to your heart and that you may feel the power of the Holy Spirit so as to witness the love which God has for every creature. I am with you and intercede for you. Thank you for having responded to my call.
        </span>
    </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";

@Component({
    components: {
       
    }
})
export default class Mantra extends Vue {


  


}



</script>

<style scoped >

.color-selector-title {
    margin: 30px 15px 0 40px;
}

.color-selector {
  z-index: 1; /* Sit on top */
  margin-top: 10px;
  background-color: white;
  border: 1px solid gray;
}

.popup-container.mat_width_info {
  margin-left: 100px;
  width: 700px;
  height: 200px;
}

    .radio-container {
        height: inherit;
        width: 100%;
    }

    .page-title {
        margin: 20px 10px;
    }
    .chooser-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        width: 80%;
        height: 80%;
        margin: 4rem 4rem 4rem 4rem;
    }
    .radio-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        width: 80%;
        height: 80%;
        margin: 4rem 4rem 4rem 4rem;
    }

    
    .search-results {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    

    .blocker {
        display: block;
        position: absolute; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 6.25rem; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    }

    /* variables */
@text-color: #222222;
@link-color: #128fdc;
@tag-color: #128fdc;

/*styles */



/* utilities 
.center {
  text-align: center;
}

*, *:before, *:after {
  box-sizing:  border-box !important;
}*/
</style>
