<template>
<div>
    <div class="chooser-container">
       
        <div class="flex-row-container">   
            <b-tooltip  v-if="this.$store.getters.showBottomRevealToolTip"
                              :type="'is-warning'"
                              :label="'Select Accent Mat Reveal'"
                              position="is-left"
                              always
                              :animated="true"
                    > 
                    </b-tooltip>
            <InfoButton :propertyId="'matWidthId'"
                        :propertyType="'matWidth'"
                        @matWidthClick="showInfo = true"/>
             <InfoPopup v-if="showInfo"
                :infoTitle="'What is the Mat Reveal?'"
                :description="description"
                :propertyType="'closeMatRevealInfo'"
                :imageName="'reveal-info.png'"
                @closeMatRevealInfoClick="closeInfo()"
            />
            <span class="--width200px --ml5"><strong>Accent Mat Reveal:</strong></span>
            <div class="flex-centered-col">  
                <CustomSelect
                :placeholder="placeholder"
                :propName="'size'"
                :disable="false"
                :optionKey="selectedKey"
                :options="this.$store.getters.revealOptions"
                @sizeSelectionChange="handleSizeChange($event)"
                />
                
                <b-tooltip   v-if="this.$store.getters.buildStep == this.$store.getters.buildSteps.SELECT_BOTTOM_MAT_REVEAL && isInitialPuzzleSetup"  
                        class="animated-2s-fade-in"
                        :type="'is-warning'"
                        :label="'Set the accent matboard reveal'"
                        position="is-bottom"
                        always
                        >
                </b-tooltip>
            </div>
        </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import * as act from "@/store/modules/ActionNames";
import * as mut from '@/store/modules/MutationNames';
import CustomSelect from  "@/components/controls/CustomSelect.vue"
import InfoButton from "@/components/buttons/InfoButton.vue"
import InfoPopup from "@/components/views/info/InfoPopup.vue"

@Component({
    components: {
        CustomSelect,
        InfoButton,
        InfoPopup
    }
})
export default class BottomMatRevealSelector extends Vue {

    description = "'Reveal' is the amount of bottom mat that is exposed underneath the top mat."
    showInfo = false;
    placeholder = "Select reveal...";
    selectedKey = null;
    selectedPuzzle = this.$store.getters.selectedPuzzle;
    topMatWidth = this.$store.getters.topMatWidth;
    isInitialPuzzleSetup!: boolean;

    created() {
        this.isInitialPuzzleSetup = this.$store.getters.isInitialPuzzleSetup

        if(this.$store.getters.buildStep > this.$store.getters.buildSteps.SELECT_BOTTOM_MAT_REVEAL) {
            this.selectedKey = this.$store.getters.bottomMatReveal
        }
    }

    closeInfo() {
        this.showInfo = false;
    }

    handleSizeChange(e) {
        this.selectedKey = e;
        this.$store.dispatch(act.SET_BOTTOM_MAT_REVEAL, e)
        this.$forceUpdate();
    }

}
</script>

<style scoped >


    .page-title {
        margin: 20px 10px;
    }

    .setting-title {
        width: 120px;
        margin-left: 10px;
    }

    .chooser-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: white;
        justify-content: flex-end;
    }

    .flex-row-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: white;
    }
    
</style>
