<template>
    <button 
        class="button"
        :disabled="disabled"
        :style="'width: 210px'"
        @click="handleClick()"
    >
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M2 19.63L13.43 8.2L12.72 7.5L14.14 6.07L12 3.89C13.2 2.7 15.09 2.7 16.27 3.89L19.87 7.5L18.45 8.91H21.29L22 9.62L18.45 13.21L17.74 12.5V9.62L16.27 11.04L15.56 10.33L4.13 21.76L2 19.63Z" /></svg>
        <span style="margin-left: 7px">
            Build Another Frame
        </span>
    </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";


@Component({
    
})
export default class BuildFrameButton extends Vue {

    @Prop()
    disabled!: boolean;

    handleClick() {
        this.$emit('buildNewFrameClick')
    }

}
</script>

<style scoped>

</style>
