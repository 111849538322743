<template>
  <div class="about">
    <div  v-if=!this.isOrderComplete class="flex-col-start" style="margin: 15px 0 0 25px;">
    
        <br>
        <h1 style="width: 600px"><strong> {{ pageTitle }}</strong></h1>
        <br>
        <div>
        <SendEmailButton 
          class=""
          @sendClick="handleSendClick()"
          :disable="!isEmailAddrValid"
        />

        <CancelButton v-if="!(requestType == 'contact')"
            style="margin-left: 20px"  
            @cancelClick="handleCancelClick()"
        />
        <SecurityButton class="--ml70"
                         :buttonText="'Privacy Policy'" 
                        :buttonWidth="'170px'"
                        :buttonId="'remove'"
                        @securityClick="$event => handlePolicyClick()"
        />
        <b-tooltip  v-if="showPrivicyToolTip" 
                class="animated-2s-fade-in"
                :type="'is-warning'"
                :label="'Your privacy is our our priority.  We do not share any customer data with outside parties.  View our full policy here.'"
                position="is-right"
                always
                multilined
        />
    </div>
        <br>
        <span><strong>To:</strong> jon@gunbarrelstudio.com</span>
    <div class="flex-row-start --mt10">
        <span><strong>From:</strong> </span>
        
    
        <EmailAddressSetting 
            class="--ml12"
            :settingTitle="''"
            :propName="'EmailAddress'"
            :settingValue="''"
            :isRequired="true"
            :disable="false"
            @validEmailAddress="validateEmailAddress($event)"
            @updateEmailAddress="setEmailAddress($event)"
        />
        <b-tooltip  v-if="showEmailToolTip" 
                class="animated-2s-fade-in"
                :type="'is-warning'"
                :label="'Enter your email address here then click the `Send` button.'"
                position="is-right"
                always
                multilined
        />
    </div>              
    <span><strong>Subject:</strong> {{ subject }}</span>
    <br>
      <TextSetting 
          :settingTitle="textBoxTitle" 
          :settingValue="''" 
          :propName="'Comments'"
          :isRequired="false"
          @updateComments="updateComments($event)"
      />
      <div v-if="requestType == 'purchase'">
        <span>Puzzle Frames: </span>
        <div v-for="lineItem in cartLineItems" :key=lineItem>
            <span> {{ lineItem }} </span>
        </div>
      </div>
      
    </div>  
    <br>
    <!--iframe v-if=!this.isOrderComplete :src=this.emailUrl id="gbsDemo" width="100%" height="750"/>
    <div v-if=this.isOrderComplete class="flex-col-start" style="margin: 0 0 0 25px;">
      <span >Thank you for your order! </span>
      <br>
      <span >Your order number is: {{ this.orderNumber }}</span>
      <br>
      <span >Please complete the purchase process at <a href="https://www.etsy.com/listing/1587405815/wood-frame-for-liberty-puzzles">www.etsy.com/wood-frame-for-liberty-puzzles</a> to finalize your order.
      </span>
      <br>
      <span >You will receive an email confirmation shortly.</span>
      <br>
      <span >Please allow 2-3 weeks for delivery.</span>

      
    
    </div-->
  </div>
</template>

<script lang="ts">
import { Component, Watch } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import SendEmailButton from "@/components/buttons/SendEmailButton.vue"
import SecurityButton from "@/components/buttons/SecurityButton.vue"
import CancelButton from "@/components/buttons/CancelButton.vue"
import EmailAddressSetting from  '@/components/controls/EmailAddressSetting.vue'
import TextSetting from  '@/components/controls/TextSetting.vue'
import * as act from '@/store/modules/ActionNames';
import { DialogProgrammatic as Dialog } from 'buefy';
import { ToastProgrammatic as Toast } from 'buefy';
import { getInchesAsString } from "@/store/GbsStore"

function sleep(seconds) {
   let milliseconds = seconds * 1000
   const promise = new Promise(resolve => {
       setTimeout(() => {
         resolve('done sleeping');
       }, milliseconds);
     });
   return promise
}

@Component({
    components: {
      SendEmailButton,
      CancelButton,
      EmailAddressSetting,
      TextSetting,
      SecurityButton
    }
})
export default class Contact extends Vue {
    isOrderComplete = false;
    orderNumber = this.$store.getters.orderNumber;
    emailAddress = "";
    cart = this.$store.getters.cart
    emailUrl = ""
    cartLineItems = new Array<string>()
    isEmailAddrValid = false
    comments=""
    orderedFrames = ""
    requestType = ""
    pageTitle = ""
    subject = ""
    textBoxTitle = ""
    showPrivacyPolicy = false;
    showPrivicyToolTip = true;
    showEmailToolTip = true;

  created() {
        this.requestType = this.$store.getters.emailRequestType
        this.setProperties()
        this.emailUrl = `https://www.gunbarrelstudio.com/email/index.html?subject=${this.subject}&ordernumber=${this.$store.getters.orderNumber}`
        console.log("emailUrl: " + this.emailUrl)
        this.cart.forEach(item => { let topMatColorTitle = "Mat Color";
                                    let topMatWidthTitle = "Mat Size";
                                    if (item.bottomMatReveal > 0) {
                                        topMatColorTitle = "Primary Mat Color";
                                        topMatWidthTitle = "Primary Mat Size";
                                    }
                                    this.cartLineItems.push(`-------`);
                                    this.cartLineItems.push(`     Puzzle:            ${item.puzzle}`);
                                    this.cartLineItems.push(`     ${topMatColorTitle}:   ${item.topMatColor}`);
                                    this.cartLineItems.push(`     ${topMatWidthTitle}:   ${getInchesAsString(1)}`);
                                    if (item.bottomMatReveal > 0) { 
                                        this.cartLineItems.push(`     Accent Mat Color :   ${item.bottomMatColor}`);
                                        this.cartLineItems.push(`     Accent Mat Reveal:   ${getInchesAsString(item.bottomMatReveal)}`);
                                    }
                                    this.cartLineItems.push(`     Wood:              ${item.wood}`);
                                    this.cartLineItems.push(`     Stain:             ${item.stain}`);
                              })
  }

  handlePolicyClick() {
    this.$router.push( "/policy");
  }

  closePrivacyPolicy() {
    this.showPrivicyToolTip  = false;
    this.showPrivacyPolicy = false;
  }

  validateEmailAddress(b: boolean) {
    this.isEmailAddrValid = b
  }

  setEmailAddress(addr: string) {
    this.showPrivicyToolTip  = false;
    this.showEmailToolTip = false;
    this.emailAddress = addr
  }
  
  handleSendClick() {
      //Dialog.confirm({
      //      title: "Confirm your email address:",
      //      message:
      //          this.emailAddress,
     //       type: "is-danger",
     //       cancelText: 'Edit Email Address',
     //       confirmText: "Send",
     //       onConfirm: () => this.sendEmail()
     //   });
    this.sendEmail();
  }

  handleCancelClick() {
    switch(this.requestType) {
      case("addPuzzle"):
        this.$router.push( "/builder");
        break;
      case("purchase"):
        this.$router.push( "/purchase");
        break;
    }
  }

  sendEmail() {
    let body = ""
    if (this.requestType == "purchase") {
      this.cartLineItems.forEach( i => {body.concat("\n", i);})
    }
    body.concat("\n", this.comments);
    const payload = {requestType: this.requestType, emailAddress: this.emailAddress, subject: this.subject, comments: this.comments}
    // payload: {requestType: string, emailAddress: string, subject: string, comments: string})
    this.$store.dispatch(act.SEND_EMAIL, payload)
    //console.log("TODO: create EMAIL_ORDER to: ", payload)
    this.$router.push( "/builder");
    Toast.open('Email has been sent')
  }

  updateComments(s: string) {
    this.comments = s
  }

  setProperties() {
    switch(this.requestType) {
      case("addPuzzle"):
        this.subject = "Add Puzzle Request"
        this.textBoxTitle = "Enter the name of the puzzle(s) to add:"
        this.pageTitle = "Enter the name of the puzzle you would like added to the list in the comments section below and I will add the puzzle ASAP and notify you when I do.";
        break;
      case("purchase"):
        this.subject = "Potential Purchase"
        this.textBoxTitle = "Other comments or questions:"
        this.pageTitle = "";
        break;
      case("contact"):
        this.subject = "Comment/Question"
        this.textBoxTitle = "Comments or questions:"
        this.pageTitle = "Type your questions or comments below and I will get back to you ASAP!";
        break;
    }
  }

  @Watch('$store.getters.emailRequestType')
  onRequestChange(value: string, oldValue: string) {
      this.requestType = value;
      this.setProperties()
  }

  @Watch('$store.getters.showReactSendEmail')
    onShowReactEmailChange(value, oldValue) {
        console.log("onShowReactEmailChange: " + value)
        
        if (!this.$store.getters.showReactSendEmail) {
          this.isOrderComplete = true;
        }
    }

  
    @Watch('$store.getters.isCanceled')
    onCanceledChange(value, oldValue) {
        console.log("onCanceledChange: " + value)
        if (value) {
          this.$router.push( "/purchase");
        }
    }


    @Watch('$store.getters.orderNumber')
    onOrderNumberChange(value, oldValue) {
        console.log("onOrderNumberChange: " + value)
        if (value) {
          this.orderNumber = this.$store.getters.orderNumber
        }
    }

}
</script>


<style scoped>
.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.header {
    margin-top: 5px;
    margin-bottom: 5px;
}
</style>
