<template>
<div class="flex-row-start">
    <div class="flex-row-start --width35px --mt5">
      <!--InfoButton
        :propertyId="'x'"
        :propertyType="'puzzleInfo'"
        @puzzleInfoClick="infoClick()"
      /-->
    </div>
    <span class="--width200px"><strong>Puzzle:</strong></span>
    <span class="--width175px">{{ this.$store.getters.selectedPuzzle.name }}</span>
    <PencilButton
      :propertyId="'x'"
      :propertyType="'editPuzzle'"
      @editPuzzleClick="editClick()"
    />
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { Vue, Prop} from "vue-property-decorator";
import InfoButton from "@/components/buttons/InfoButton.vue"
import PencilButton from "@/components/buttons/PencilButton.vue"

@Component({
    components: {
        InfoButton,
        PencilButton
    }
})
export default class LineItem extends Vue {
  
  infoClick() {
    this.$emit("puzzleInfoClick")
  }

  
  editClick() {
      console.log("emmitting puzzle click")
      this.$emit("editPuzzleClick")
  }

  


}



</script>

<style scoped >

.color-selector-title {
    margin: 30px 15px 0 40px;
}

.color-selector {
  z-index: 1; /* Sit on top */
  margin-top: 10px;
  background-color: white;
  border: 1px solid gray;
}

.popup-container.mat_width_info {
  margin-left: 100px;
  width: 700px;
  height: 200px;
}

    .radio-container {
        height: inherit;
        width: 100%;
    }

    .page-title {
        margin: 20px 10px;
    }
    .chooser-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        width: 80%;
        height: 80%;
        margin: 4rem 4rem 4rem 4rem;
    }
    .radio-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        width: 80%;
        height: 80%;
        margin: 4rem 4rem 4rem 4rem;
    }

    
    .search-results {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    

    .blocker {
        display: block;
        position: absolute; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 6.25rem; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    }

    /* variables */
@text-color: #222222;
@link-color: #128fdc;
@tag-color: #128fdc;

/*styles */



/* utilities 
.center {
  text-align: center;
}

*, *:before, *:after {
  box-sizing:  border-box !important;
}*/
</style>
