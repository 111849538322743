<template>
  <div class="home">  
      <span style="margin: 10px 0 0 0">We build customized durable, high quality, picture frames for <a href="https://www.libertypuzzles.com/">Liberty Puzzles</a>,</span>
      <span>enabling an easy tool free way to load, frame, and unload your puzzles.</span>
    
    <div style="margin-left: 10px; max-width: 1200px;">
        <Spinner v-if="!isImageLoaded"></Spinner>
        <button class="invisible-button" @click="onPuzzleClick">
            <img v-show="isImageLoaded" @load="onImgLoad"  src="../../assets/images/samples/ColoradoFlag.png"/>
            <!--img src="../../assets/images/samples/crested_butte_frame.png"/-->
        </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import Spinner from "@/components/views/Spinner.vue"

@Component({
    components: {
      Spinner
    }
})
export default class Home extends Vue {
  isImageLoaded = false;

  created() {
      //console.log("Home page has been created.")
  }

  onImgLoad() {
        this.isImageLoaded = true;
    }


  onPuzzleClick() {
      this.$router.push( "/demo");
  }
}
</script>

<style scoped>

.center {
  text-align: center;
}
.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
