<template>
  <div class="flx-row-space-between  --mt5">
    <div class="flex-row-start --width35px --mt5">
            <InfoPopup v-if="showInfo"
                :infoTitle="'Single vs. Double Mat...'"
                :propertyType="'closeMatTypeInfo'"
                :width="'800'"
                :imageName="'mat_type_info.jpeg'"
                @closeMatTypeInfoClick="closeInfo()"
            />
      <InfoButton :propertyId="'matTypeId'"
                  :propertyType="'matTypeInfo'"
                  @matTypeInfoClick="showInfo = true"
      />
    </div>
    <div class="flex-row-start --width100px">    
          <span class="title-text"><strong>Mat type:</strong></span>
    </div>     
    <div class="flex-centered-col">
      <div class="flex-row-start">
        <div class ="first-button">
          <!--b-radio v-model="matTypeName"
              name="matType"
              type=is-black
              native-value="single">
              Single Mat
          </b-radio-->
        </div>
        <div class ="second-button">
          <b-radio  v-model="matTypeName"
              name="matType"
              type=is-black
              native-value="double">
              Double Mat
          </b-radio>
        </div>
      </div>
      <b-tooltip  v-if="this.$store.getters.buildStep == this.$store.getters.buildSteps.SELECT_MAT_TYPE && this.$store.getters.isInitialPuzzleSetup && this.matTypeName == 'Unselected'"  
          class="animated-2s-fade-in"
          :type="'is-warning'"
          :label="'Set the margins size of the matboard'"
          position="is-bottom"
          always
      />
    </div>
  </div>
    
      
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import * as act from '@/store/modules/ActionNames';
import InfoButton from "@/components/buttons/InfoButton.vue"
import InfoPopup from "@/components/views/info/InfoPopup.vue"

@Component({
  components: {
        InfoButton,
        InfoPopup
    }
})
export default class RadioButtons extends Vue {
  matType = this.$store.getters.matType
  matTypes = this.$store.getters.matTypes
  matTypeName = "Unselected";
  showInfo = false;

  created() {
    if(this.matType == this.matTypes.SINGLE) {
        this.matTypeName = "single"
    }
    if(this.matType == this.matTypes.DOUBLE) {
      this.matTypeName = "double"
    }
  }

  closeInfo() {
        this.showInfo = false;
  }

  @Watch('matTypeName')
  onNameChange() {
    let mtype = 0
    if(this.matTypeName == "single") {
        mtype = this.matTypes.SINGLE;
    }
    if(this.matTypeName == "double") {
      mtype = this.matTypes.DOUBLE
    }
    this.$store.dispatch(act.SET_MAT_TYPE, mtype )
  }

  @Watch('$store.getters.matType')
  onMatTypeChange(value: number) {
    if(value == this.matTypes.SINGLE) {
        this.matTypeName = "single"
    }
    if(value == this.matTypes.DOUBLE) {
      this.matTypeName = "double"
    }
  }
}

</script>

<style scoped>
.title-text {
  margin-top: 0px;
  margin-right: 10px;
}

.first-button {
  margin-left: 100px;
}
.second-button {
  margin-left: 20px;
}
</style>