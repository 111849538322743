<template>
    <!--This is the top mat with an outer boarder to represent the frame?-->
    <div :style="'border: 30px solid transparent; ' +
        'border-image: url(img/' + $store.getters.selectedStainImageName + ') 50 stretch;'"
    >
        <!--This is the top mat with an outer boarder to represent what?-->
        <div id="topMat" :style="       'border: 0px solid transparent;' +
                                              'background-image: url(' + this.$store.getters.topMatImagePath + ');' +
                                              'height: ' + this.$store.getters.frameHeightPixels + ';' +
                                              'width: ' + this.$store.getters.frameWidthPixels + ';'"
        >
            <!--This is the bottom mat with an outer white boarder to represent the beveled edge of the top mat-->
            <div class="flex-row-centered" 
                :style="bottomMatStyle"
                id="bottomMat"
            >
                <!--The PuzzleImage component will put a border on it to for the bevel edge directly above it-->    
                <Spinner v-if="!isImageLoaded"></Spinner>
                <PuzzleImageFramed v-show="isImageLoaded" 
                            :height="puzzle.height"
                            :width="puzzle.width"
                            :pixelRatio="pixelRatio"
                            :imageName="puzzle.imgName"
                            :catagory="puzzle.catagory"
                />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import PuzzleImageFramed from  "@/components/puzzles/PuzzleImageFramed.vue"
import MatImage from "@/components/mat/MatImage.vue"
import Spinner from "@/components/views/Spinner.vue"
import {MatTypes, DEFAULT_TOP_MAT, DEFAULT_BOTTOM_MAT} from "@/store/GbsStore"



@Component({
    components: {
        PuzzleImageFramed,
        MatImage,
        Spinner
    }
})
export default class DoubleMatPuzzleFrame extends Vue {
    pixelRatio!: number;
    matTypes = MatTypes
    beveledEdge = "border: 3px solid white;"
    bottomMatStyle = ""
    //hasDoubleMat = ;
    defaultTopMat = DEFAULT_TOP_MAT
    isImageLoaded = false;

    @Prop()
    puzzle;

    created() {
        this.pixelRatio = this.$store.getters.pixelRatio;
        this.$forceUpdate
        this.setBottomMatStyle()
    }


    setBottomMatStyle() {
        let sty = ""
        if(this.$store.getters.matType == MatTypes.DOUBLE) {
            let heightPx = (this.$store.getters.puzzleHeightPixels + this.$store.getters.bottomMatPixels ) + 'px' //This extra number is what determines the width of the bottom mat
            let widthPx = (this.$store.getters.puzzleWidthPixels + this.$store.getters.bottomMatPixels ) + 'px'  // and for now we aren't going to offer any options to change this
            
            /*//console.log("puzzleHeightPixels             : " + this.$store.getters.puzzleHeightPixels)
            //console.log("puzzleWidthPixels              : " + this.$store.getters.puzzleWidthPixels)
            //console.log("setBottomMatStyle heightPx     : " + heightPx)
            //console.log("setBottomMatStyle widthPx      : " + widthPx)
            //console.log("bottomMatPixels                : " + this.$store.getters.bottomMatPixels)*/
            sty =     this.beveledEdge +
                        "height: " + heightPx + ";" +
                        "width: " + widthPx + ";" +
                        "background-image: url(" + this.$store.getters.bottomMatImagePath + ");"
        }
        this.bottomMatStyle =  sty
    }

    //For now we aren't going to offer any option to alter bevel width
    /*@Watch('$store.getters.matBevelWidth')
    onBevelEdgeChange(value: number) {
        //console.log("onBevelEdgeChange got: ", value)
        this.bevelEdge = "border: " + value + "px solid white;"
         this.$forceUpdate()
    }*/

    @Watch('$store.getters.isMatWithCore')
    onIsMatWithCoreChange(hasCore: boolean) {
        //console.log("onIsMatWithCoreChange got: ", hasCore)
        if (hasCore) {
            this.beveledEdge = "border: 3px solid white;"
        }
        else {
            this.beveledEdge = ""
        }
         this.$forceUpdate()
    }

    
    @Watch('$store.getters.matType')
    onMatTypeChange(value: number) {
        if (value == this.matTypes.DOUBLE) {
            this.setBottomMatStyle();
        }
        else {
            this.bottomMatStyle = "";
        }
        this.$forceUpdate()

    }

    @Watch('$store.getters.selectedPuzzle')
    onPuzzleChange(value: string) {
        //console.log("frame heard puzzle change to: " + value)
        this.setBottomMatStyle()
         this.$forceUpdate()
    }
    
    @Watch('$store.getters.bottomMatImagePath')
    onBottomMatImagePathChange(value: string) {
        this.setBottomMatStyle()
         this.$forceUpdate()
    }

    @Watch('$store.getters.topMatImagePath')
    onTopMatImagePathChange(value: string) {
         this.$forceUpdate()
    }

    @Watch('$store.getters.bottomMatPixels')
    onBottomMatPixChange(value: number) {
        ////console.log("frame heard bottomMatPixels change to: " + value)
        this.setBottomMatStyle()
        this.$forceUpdate()
    }

    @Watch('$store.getters.isPuzzleLoaded')
    onPuzzleLoadEvent(value: boolean) {
        this.isImageLoaded = value
    }
}
</script>

<style scoped>
#topMat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.beveled-edge {
  border: 3px solid blue;
}

</style>