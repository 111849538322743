import { render, staticRenderFns } from "./FullScreenPreview.vue?vue&type=template&id=2103248f&scoped=true"
import script from "./FullScreenPreview.vue?vue&type=script&lang=ts"
export * from "./FullScreenPreview.vue?vue&type=script&lang=ts"
import style0 from "./FullScreenPreview.vue?vue&type=style&index=0&id=2103248f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2103248f",
  null
  
)

export default component.exports