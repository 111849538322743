import { render, staticRenderFns } from "./AddToCartButton.vue?vue&type=template&id=83af684c&scoped=true"
import script from "./AddToCartButton.vue?vue&type=script&lang=ts"
export * from "./AddToCartButton.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83af684c",
  null
  
)

export default component.exports