// RestStore
export const GET_FRAME_STYLES = 'actGetFrameStyles'
export const GET_INVENTORY = 'actGetInventory'
export const CONFIGURE_PUZZLE_BUILDER = 'actConfigurePuzzleBuilder'
export const START_NEW_PUZZLE = 'actStartNewPuzzle'
export const GENERATE_FRAME_INVENTORY = "actGenerateFrameInventory"
export const GENERATE_MAT_INVENTORY = "actGenerateMatInventory"
export const CALCULATE_MAT_SIZES = "actGenerateMatSizes"
export const CREATE_MAT_INVENTORY = "actGenerateMatInventory"
export const ADD_ORDER_TO_CART = "actAddItemToCart"
export const REMOVE_ORDER_FROM_CART = "actRemoveItemFromCart"
export const SET_TOP_MAT_WIDTH = "actSetTopWidth"
export const SET_TOP_MAT_COLOR = "actSetTopMatColor"
export const SET_BOTTOM_MAT_COLOR = "actSetBottomMatColor"
export const SET_SELECTED_WOOD = "actSetSelectedWood"
export const SET_SELECTED_STAIN = "actSetSelectedStain"
export const SET_PRICE = "actSetPrice"
export const SEND_EMAIL = "actSendEmail"
export const ADD_TEST_ORDER_TO_CART = "actTestOrder"
export const SET_BOTTOM_MAT_REVEAL = "actSetBottomMatReveal"
export const GENERATE_PUZZLE_INVENTORY = "actGeneratePuzzleInventory"
export const INCREMENT_BUILD_STEP = "actIncrementBuildStep"
export const SET_MAT_TYPE = "actMatType"
export const SAVE_ORDER_ON_SERVER = "actSaveOrderOnServer"
